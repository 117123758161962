<template>
  <div class="auto-page">
    <div class="begin mt-6 text-black text-xl">
      <div class="title font-medium text-black text-4xl flex items-baseline">
        <div class="ic w-10">
          <svg-icon
            name="ic_car_insurance"
            original
            class="w-8 h-8"
          />
        </div>
        <div class="title-text ml-4">{{ $t('automobileInsurance') }}</div>
      </div><br>
      <div class="sous-title text-2xl">{{ $t('manyGuaranteesInsurance') }}</div><br>
      <div class="description leading-relaxed" v-html="$t('autoInsuranceDescription')"></div>
      <div class="what-guarantees text-2xl font-medium mt-8">{{ $t('whatIsGuarantees') }}</div><br>
      <div class="guarantees-description">
        <div
          v-for="(item, index) in guarantees"
          :key="index"
          class="conteneur flex items-baseline mb-4"
        >
          <div class="radio w-1 h-1 border-2 p-2 rounded mr-4"></div>
          <div class="gua-desc leading-relaxed">
            <div class="short-title font-medium mb-4">{{ item.title }}</div>
            <div v-if="item.description !== ''" class="short-desc" v-html="item.description"></div>
          </div>
        </div>
      </div>
      <div class="non-necessary text-2xl font-medium mt-4">{{ $t('nonNecessaryGuarantees') }}</div>
      <div class="non-necessary-desc leading-relaxed mt-4" v-html="$t('extendCoverLevel')"></div>
      <div class="bloc-list flex items-center mt-4">
        <div
          v-for="(item, index) in necessaries"
          :key="index"
          class="list border rounded-2xl py-8 w-full flex justify-center items-center mr-4"
        >
          <div class="flex flex-col justify-center">
            <div class="icons mb-4">
              <svg-icon
                :name="item.icon"
                original
                class="w-8 h-8"
              />
            </div>
            <div class="cont text-center">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="subscribe text-xl font-medium mt-8">{{ $t('whoCanSubscribe') }}</div>
      <div class="non-necessary-desc leading-relaxed mt-4">{{ $t('allCarLandlord') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'auto-page',
  data () {
    return {
      guarantees: [
        {
          title: this.$t('civicResponsibility'),
          description: this.$t('responsibilityDescription')
        },
        {
          title: this.$t('theCEDEOA'),
          description: this.$t('cedeaoDescription')
        },
        {
          title: this.$t('complementaryGuarantees'),
          description: ''
        }
      ],
      necessaries: [
        {
          icon: 'ic_glass',
          name: this.$t('glass')
        },
        {
          icon: 'ic_thief',
          name: this.$t('thief')
        },
        {
          icon: 'ic_collision',
          name: this.$t('collision')
        },
        {
          icon: 'ic_flame',
          name: this.$t('fire')
        },
        {
          icon: 'ic_all_risk',
          name: this.$t('allRisk')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .sous-title {
    color: $aab_gray;
  }
  .svg-fill {
    fill: transparent;
  }
  .non-necessary {
    color: $aab_yellow;
  }
  .list {
    &:last-child {
      margin-right: 0;
    }
  }
  .icons {
    text-align: -moz-center;
    text-align: -webkit-center;
  }
  .radio {
    border-color: $aab_radio_color;
    padding: .35rem;
  }
  @media only screen and (max-width: 600px) {
    .bloc-list {
      flex-wrap: wrap;
    }
    .list {
      width: 45%;
      margin-bottom: 1.5rem;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .bloc-list {
      flex-wrap: wrap;
    }
    .list {
      width: 45%;
      margin-bottom: 1.5rem;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .bloc-list {
      flex-wrap: wrap;
    }
    .list {
      width: 45%;
      margin-bottom: 1.5rem;
    }
  }
</style>
